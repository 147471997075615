import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Box, Button } from "theme-ui";
import { navigate } from "gatsby";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="404 - Page Not Found" description="" mdxType="SEO" />
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
      <h1 style={{
        marginTop: "0px"
      }}>
Uh oh!
      </h1>
      <p>{`The page you are seeking is not here.`}</p>
      <p>{`Please return Home to consider your options.`}</p>
      <Button variant="primary" title="Navigate to our home page" onClick={() => navigate("/", {
        replace: true
      })} sx={{
        marginTop: "100px",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block"
      }} mdxType="Button">
  Take me home
      </Button>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      